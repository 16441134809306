import React, { FC } from 'react';
import { BundleComponent as Organism } from '@components/Organisms';

export const BundleComponent = ({
  display,
  title,
  primary_bundle_product_id,
  bundle_description,
  image,
  internal_link,
  bundleVariant2024Sept,
  bundleProducts,
  primaryBundleProduct,
}) => {
  return (
    <Organism
      display={display}
      image={image}
      title={title}
      primaryBundleProductId={primary_bundle_product_id}
      bundleDescription={bundle_description}
      internalLink={internal_link}
      bundleVariant2024Sept={bundleVariant2024Sept}
      bundleProducts={bundleProducts}
      primaryBundleProduct={primaryBundleProduct}
    />
  );
};
