import React, { FC } from 'react';
import { StepsInfoT } from './types';
import { StepsInfo as Organism } from '@components/Organisms';

export const StepsInfo: FC<StepsInfoT> = ({
  display,
  title,
  buttonText,
  infoSteps,
  theme,
  peepingBennyImage,
  bundleVariant2024Sept,
  bundleProducts,
}) => {
  return (
    <Organism
      display={display}
      title={title}
      buttonText={buttonText}
      infoSteps={infoSteps}
      theme={theme}
      peepingBennyImage={peepingBennyImage}
      bundleVariant2024Sept={bundleVariant2024Sept}
      bundleProducts={bundleProducts}
    />
  );
};
