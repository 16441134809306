import React, { FC } from 'react';
import { CardColumn as Organism } from '@components/Organisms';

export const CardColumn = ({
  display,
  title,
  cards,
  buttonText,
  bundleVariant2024Sept,
  bundleProducts,
}) => {
  return (
    <Organism
      display={display}
      title={title}
      cards={cards}
      buttonText={buttonText}
      bundleVariant2024Sept={bundleVariant2024Sept}
      bundleProducts={bundleProducts}
    />
  );
};
